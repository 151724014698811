/**
 * HOME画面のクエリパラメータコントロール用middleware
 */
import { selectorModeValue, searchTypeValue } from '@/features/parking/types';
import { useParkingState } from '@/composables/store/useParkingStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { storedParkingsOnLists } = useParkingState();
  const { isNatural } = useValidation();

  // ブラウザバックした時、検索条件が前と同じならpageパラメータを復元
  if (
    to.query.selectorMode === 'parking' &&
    to.query.searchType === storedParkingsOnLists.value.searchType &&
    to.query.page !== String(storedParkingsOnLists.value.page)
  ) {
    // キーワード検索の場合は復元しない
    if (to.query.keywords && from.query.keywords) return;

    return navigateTo({
      path: '/',
      query: {
        ...to.query,
        selectorMode: 'parking',
        searchType: storedParkingsOnLists.value.searchType,
        page: storedParkingsOnLists.value.page,
      },
    });
  }

  // パラメータが不正な場合は位置検索のHOME画面にリダイレクト
  if (
    !selectorModeValue.some((v) => v === to.query.selectorMode) ||
    (to.query.searchType &&
      !searchTypeValue.some((v) => v === to.query.searchType)) ||
    (to.query.page && !isNatural(String(to.query.page)))
  ) {
    return navigateTo({
      path: '/',
      query: {
        ...to.query,
        selectorMode: 'parking',
        searchType: 'currentPlace',
        page: 1,
      },
    });
  }
});
